import React, { useState } from "react";
import Image from "../../../../../components/Image";
import styles from "./FeatureTeaser.module.scss";
import Button from "../../../../../components/Button";
import Tooltip from "../../../../../components/Tooltip";
import ConditionalWrapper from "../../../../../components/ConditionalWrapper";
import PropTypes from "prop-types";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { InView } from "react-intersection-observer";

const FeatureTeaser = ({
  title,
  boldTitle,
  endTitle,
  description,
  imageSrc,
  videoSrc,
  disableButton = false,
  modusHeader = false,
  overlay = false,
}) => {
  const [playVideo, setPlayVideo] = useState(false);
  const windowSize = useWindowSize();

  const videoId = videoSrc ? `featureVideo-${title}` : null;

  const handleEnterHover = (e) => {
    setPlayVideo(true);
    if (videoId) {
      document.getElementById(videoId)?.play();
    }
  };
  const handleLeaveHover = (e) => {
    setPlayVideo(false);
    if (videoId) {
      const videoElement = document.getElementById(videoId);
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    }
  };

  const handlerOnView = (inView, entry) => {
    if (windowSize.width < 992) {
      if (inView) {
        handleEnterHover(entry);
      } else {
        handleLeaveHover(entry);
      }
    }
  };

  return (
    <InView
      className={`${styles.featureTeaser} ${
        modusHeader && styles.featureTeaserOver
      }`}
      onMouseEnter={handleEnterHover}
      onMouseLeave={handleLeaveHover}
      threshold={0.85}
      onChange={handlerOnView}
    >
      <div className={styles.head}>
        <div
          className={`ce-ratio-box ce-ratio-box--${
            modusHeader ? (windowSize.width < 768 ? "" : "wide") : "16-9"
          }`}
        >
          <div className="ce-ratio-box__content ">
            {imageSrc && (
              <Image
                src={imageSrc}
                nextOptimizedImage
                layout="fill"
                className={`object-fit ${styles.image} ${
                  playVideo && styles.play
                }`}
                alt={`video ${title}`}
              />
            )}
            {videoSrc && (
              <video
                id={`${videoId}`}
                className={`object-fit ${styles.video} ${
                  playVideo && styles.play
                }`}
                muted
                poster={imageSrc}
              >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {overlay && (
              <div
                className={`${styles.overlay} ${playVideo && styles.play}`}
              ></div>
            )}
          </div>
        </div>
      </div>
      {modusHeader ? (
        <div className={styles.content}>
          <p>{description}</p>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.contentText}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          {!disableButton && (
            <ConditionalWrapper
              condition={featureNotAllowedTooltipText}
              wrapper={(children) => (
                <Tooltip icon="" text={featureNotAllowedTooltipText}>
                  {children}
                </Tooltip>
              )}
            >
              <div>
                <Button
                  layout="primary"
                  text="Zum Feature"
                  disabled={!hasRequiredRole}
                />
              </div>
            </ConditionalWrapper>
          )}
        </div>
      )}
    </InView>
  );
};

FeatureTeaser.propTypes = {
  title: PropTypes.string,
  boldTitle: PropTypes.string,
  endTitle: PropTypes.string,
  description: PropTypes.string,
  imageSrc: PropTypes.string,
  videoSrc: PropTypes.string,
  disableButton: PropTypes.bool,
  modusHeader: PropTypes.bool,
  overlay: PropTypes.bool,
};

export default FeatureTeaser;
